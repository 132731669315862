import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import setDevCookie from '@/utils/setDevCookie.js';
import enableVueDebug from './utils/enableVueDebug.js';
import { createHead } from '@unhead/vue';

import i18n from './i18n';

const head = createHead();
const pinia = createPinia();
const app = createApp(App);
app.config.performance = !!localStorage.getItem('vue-debug');

window.enableVueDebug = enableVueDebug;

if (process.env.NODE_ENV === 'development' && (new URL(window.location).searchParams.get('dev'))) {
  setDevCookie();
}

app.config.performance = new URL(window.location).searchParams.get('performance') === 1;

app.use(head);
app.use(pinia);
app.use(router);
app.use(i18n);
app.mount('#app');
